<div class="center-screen consumerScreen">
  <div style="display: block;">
    <div class="topBar p-t-15 p-l-15" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
      <div class="consumerTitle" style="margin-bottom: 0;">{{consumerTitle}}</div>
    </div>
    <div class="consumerContent centerDiv" *ngIf="!beginRequest" style="display:inline-grid;">
      <div class="row" *ngIf="companyLogoUrl">
        <div class="col"><img class="logo" src="{{companyLogoUrl}}" alt="{{consumerInfo.companyName}}"/></div>
      </div>
      <div class="row m-t-20" style="text-align: center ;">
        <div class="col"><button mat-raised-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" (click)="begin()">BEGIN</button></div>
      </div>
    </div>
    <mat-stepper [linear]="true" #stepper *ngIf="beginRequest">
      <ng-template matStepperIcon="product">
        <mat-icon >home</mat-icon>
      </ng-template>
      <mat-step label="CHOOSE YOUR PRODUCT" state="product">

        <div class="consumerContent">
          <div class="row m-b-20 m-t-20">
            <div class="col justify-content-center consumerTitle">
              <h2>{{consumerInfo?.welcomeMessage}}</h2>
            </div>
          </div>
          <mat-divider class="m-t-10 m-b-10"></mat-divider>
          <div class="row justify-content-center">
            <div class="col"  *ngFor="let item of consumerInfo?.categories">
              <h3 [style.color]="clientColors?.CategoryHeadingTextColor"><i [ngClass]="getDesctiptionIcon(item.description)" class="m-r-10"></i>{{item.description}}</h3>
              <div class="loanItem" [ngClass]="{'selectedProduct': product.Id == selectedProduct.product.Id}" *ngFor="let product of item.products" (click)="selectProduct(product, item)">
                  {{product.description}}
              </div>
            </div>
          </div>
          <mat-divider class="m-t-10 m-b-10"></mat-divider>
        </div>
      </mat-step>
      <mat-step label="YOUR INFORMATION" [stepControl]="informationForm">
        <ng-template matStepContent>
          <form [formGroup]="informationForm">
            <div class="consumerContent">
              <div *ngIf="selectedProduct.product.Id" class="m-b-25">
                <app-consumer-selected-product (changedProduct)="goToStepperIndex($event)" [branding]="clientColors" [selectedProduct]="selectedProduct.product" [logo]="selectedProduct.icon"></app-consumer-selected-product>
              </div>
              <h3>Personal Information</h3>
              <div class="row">
                <div class="col">First Name <span style="color: red;">*</span></div>
                <div class="col">Middle Name</div>
                <div class="col">Last Name <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-15">
                <div class="col "><input type="text" class="form-control" formControlName="firstName" placeholder="First Name"></div>
                <div class="col"><input type="text" class="form-control" formControlName="middleName" placeholder="Middle Name"></div>
                <div class="col"><input type="text" class="form-control" formControlName="lastName" placeholder="Last Name"></div>
              </div>
              <div class="row">
                <div class="col">Email <span style="color: red;">*</span></div>
                <div class="col">SSN</div>
                <div class="col">Contact Phone <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-20">
                <div class="col"><input type="text" class="form-control" formControlName="email" placeholder="Email"></div>
                <div class="col"><input type="text" class="form-control" formControlName="ssn"  mask="000-00-0000" placeholder="xxx-xx-xxxx"></div>
                <div class="col"><input type="text" class="form-control" formControlName="contactPhone" mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx"></div>
              </div>
              <mat-divider class="m-t-20 m-b-25"></mat-divider>
              <h3>Current Address</h3>
              <span>Do not enter PO Box address</span>
              <div class="row m-t-10">
                <div class="col-5">Address <span style="color: red;">*</span></div>
                <div class="col">Zip <span style="color: red;">*</span></div>
                <div class="col">City <span style="color: red;">*</span></div>
                <div class="col">State <span style="color: red;">*</span></div>
              </div>
              <div class="row m-b-30">
                <div class="col-5"><input type="text" class="form-control" formControlName="address" placeholder="Address"></div>
                <div class="col"><input type="text" class="form-control" formControlName="zip" (change)="zipcodeLookUp(requestInfo.zip)" placeholder="Zip"></div>
                <div class="col"><input type="text" class="form-control" formControlName="city" placeholder="City"></div>
                <div class="col">
                  <mat-select class="form-control" formControlName="state" placeholder="State">
                    <mat-option [value]="item.abbreviation" *ngFor="let item of statesList">{{item.abbreviation}}</mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="p-a-10" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
                <button mat-flat-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" [disabled]="!informationForm.valid" (click)="goForward()">NEXT</button>
              </div>
            </div>
          </form>
        </ng-template>
      </mat-step>
      <mat-step label="AGREEMENTS">
        <ng-template matStepContent>
          <div class="consumerContent"  >
            <div *ngIf="selectedProduct.product.Id" class="m-b-20">
              <app-consumer-selected-product (changedProduct)="goToStepperIndex($event)" [branding]="clientColors" [selectedProduct]="selectedProduct.product" [logo]="selectedProduct.icon"></app-consumer-selected-product>
            </div>
            <app-consumer-agreement [branding]="clientColors" [consumerInfo]="consumerInfo"></app-consumer-agreement>
            <div class="m-t-45 m-b-45" style="text-align: center;">Click "agree" to indicate your acceptance of the agreements above. <button mat-flat-button class="btn btn-success" color="primary" (click)="sendRequest()">AGREE</button></div>
            <div class="p-a-10" style="text-align: center;" [style.background-color]="clientColors?.HeaderBackgroundColor" [style.color]="clientColors?.HeaderTextColor">
              <button mat-flat-button style="color: #fefefe;" [style.background-color]="clientColors?.ButtonBackgroundColor" (click)="goBack()" >BACK</button>
            </div>
          </div>
        </ng-template>
      </mat-step>
      <mat-step label="FINISH">
        <ng-template matStepContent>
          <div class="consumerContent" *ngIf="requestResponse">
            <app-consumer-finish [branding]="clientColors" [selectedProduct]="selectedProduct.product" [logo]="selectedProduct.icon" [branding]="clientColors" [companyLogoUrl]="companyLogoUrl" [requestResponse]="requestResponse" [consumerInfo]="consumerInfo"></app-consumer-finish>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>
